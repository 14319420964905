define('ember-simple-auth/instance-initializers/setup-session-restoration', ['exports', 'ember-simple-auth/utils/lookup'], function (exports, _lookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setupSessionRestoration;
  function setupSessionRestoration(instance) {
    const applicationRoute = (0, _lookup.default)(instance, 'route:application');
    const session = (0, _lookup.default)(instance, 'session:main');
    const originalBeforeModel = applicationRoute.beforeModel;
    const applyOriginalBeforeModel = function () {
      return originalBeforeModel.apply(applicationRoute, arguments);
    };
    applicationRoute.reopen({
      beforeModel() {
        return session.restore().then(() => applyOriginalBeforeModel(...arguments), () => applyOriginalBeforeModel(...arguments));
      }
    });
  }
});