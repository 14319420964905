define('ember-macro-helpers/create-class-computed', ['exports', 'ember-weakmap', 'ember-macro-helpers/get-value', 'ember-macro-helpers/collapse-keys', 'ember-macro-helpers/flatten-keys'], function (exports, _emberWeakmap, _getValue, _collapseKeys, _flattenKeys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (observerBools, macroGenerator) {
    return function (...keys) {
      let { collapsedKeys, keyMap } = (0, _collapseKeys.collapseKeysWithMap)(keys);

      function getOriginalArrayDecorator(key, i) {
        if (typeof key === 'string') {
          let originalKey = keys[keyMap[i]];
          if (originalKey.indexOf('.[]') !== -1 || originalKey.indexOf('.@each') !== -1) {
            return originalKey;
          }
        }
        return key;
      }

      let mappedKeys = [];

      function rewriteComputed() {
        let mappedWithResolvedOberverKeys = mappedKeys.map((macro, i) => {
          let shouldObserve = observerBools[i];
          if (shouldObserve) {
            macro = (0, _getValue.default)({ context: this, macro });
          }
          return macro;
        });

        let cp = macroGenerator.apply(this, mappedWithResolvedOberverKeys);
        defineProperty(this, 'computed', cp);
      }

      let classProperties = {};

      collapsedKeys.forEach((key, i) => {
        let shouldObserve = observerBools[i];
        if (!shouldObserve) {
          key = getOriginalArrayDecorator(key, i);
        }

        let mappedKey = resolveMappedLocation(key, i);

        mappedKeys.push(mappedKey);
        if (shouldObserve) {
          classProperties[`key${i}DidChange`] = Ember.observer(mappedKey, rewriteComputed);
        }
      });

      let ObserverClass = BaseClass.extend(classProperties, {
        // can't use rewriteComputed directly, maybe a bug
        // https://github.com/emberjs/ember.js/issues/15246
        onInit: Ember.on('init', function () {
          rewriteComputed.call(this);
        })
      });

      return Ember.computed(...(0, _flattenKeys.default)(keys), function (key) {
        let propertyInstance = findOrCreatePropertyInstance(this, ObserverClass, key);

        let properties = collapsedKeys.reduce((properties, macro, i) => {
          if (typeof macro !== 'string') {
            properties[i.toString()] = (0, _getValue.default)({ context: this, macro });
          }
          return properties;
        }, {});

        Ember.setProperties(propertyInstance.nonStrings, properties);

        return Ember.get(propertyInstance, 'computed');
      }).readOnly();
    };
  };

  // import getOwner from 'ember-owner/get';
  const { defineProperty } = Ember;

  const PROPERTIES = new _emberWeakmap.default();

  function findOrCreatePropertyInstance(context, propertyClass, key) {
    let isComponent = context instanceof Ember.Component;

    let propertiesForContext = PROPERTIES.get(context);
    if (Ember.isNone(propertiesForContext)) {
      propertiesForContext = {};
      PROPERTIES.set(context, propertiesForContext);

      if (isComponent) {
        context.one('willDestroyElement', () => {
          PROPERTIES.delete(context);
        });
      }
    }

    let property = propertiesForContext[key];
    if (property) {
      return property;
    }

    // let owner = getOwner(context);
    property = propertyClass.create( /*owner.ownerInjection(), */{
      key,
      context,
      nonStrings: Ember.Object.create()
    });

    propertiesForContext[key] = property;

    if (isComponent) {
      context.one('willDestroyElement', () => {
        property.destroy();
        delete propertiesForContext[key];
      });
    }

    return property;
  }

  const BaseClass = Ember.Object.extend({
    computedDidChange: Ember.observer('computed', function () {
      this.context.notifyPropertyChange(this.key);
    })
  });

  function resolveMappedLocation(key, i) {
    if (typeof key === 'string') {
      return `context.${key}`;
    } else {
      return `nonStrings.${i}`;
    }
  }
});